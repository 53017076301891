export const pressClippings = {
    0: {
        title: 'Marriage Announcement of William F. Lee of Hermitage',
        filePath: 'press/alexandria_gazette_10_november_1843_william_lee_higgs_marriage_hermitage.jpeg',
        citation: '"Announcements", <em>Alexandria Gazette</em>, November 10, 1843.',
        year: 1843
    },
    1: {
        title: 'Marriage Announcement of Thomas W. Lee II to Maggie Haight',
        filePath: 'press/alexandria_gazette_10_october_1874_haight_thomas_lee_marriage.jpeg',
        citation: '"Announcements", <em>Alexandria Gazette</em>, October 10, 1874.',
        year: 1874
    },
    2: {
        title: 'Sully and Leeton amidst Chantilly Airport Planning',
        filePath: 'press/northern_virginia_sun_31_january_1958_sully_leeton.jpeg',
        citation: 'Templeman, Eleanor Lee. "Chantilly Has Historic Homes." <em>Northern Virginia Sun</em>. January 31, 1958.',
        year: 1958
    },
    3: {
        title: 'Ewes for Sale at Sully',
        filePath: 'press/alexandria_gazette_commercial_and_political_23_june_1814_ewes_sully_sale.jpeg',
        citation: '"For Sale", <em>Alexandria Gazette Commercial &amp; Political</em>, June 23, 1814.',
        year: 1814
    },
    4: {
        title: 'Sully for Sale',
        filePath: 'press/alexandria_gazette_and_daily_advertiser_6_february_1818_chantilly_and_sully_sale.jpeg',
        citation: '"For Sale or Rent", <em>Alexandria Gazette &amp; Daily Advertiser</em>, February 6, 1818.',
        year: 1818
    },
    5: {
        title: 'Runaway Slave Notice from Charles W. Turley',
        filePath: 'press/alexandria_gazette_24_march_1855_turley_runaway_slave.jpeg',
        citation: '"Notices", <em>Alexandria Gazette</em>, March 24, 1855.',
        year: 1855
    },
    6: {
        title: 'Avon for Sale',
        filePath: 'press/alexandria_gazette_6_november_1846_avon_sale_stuart.JPG',
        citation: '"For Sale or Rent", <em>Alexandria Gazette </em>, Nov 6, 1846.',
        year: 1846
    },
    7: {
        title: 'H. Douglas Smith Anniversary at Avon',
        filePath: 'press/fairfax_herald_10_dec_1948_smith_avon_chantilly.JPG',
        citation: '“Out Chantilly Way”, <em>Fairfax Herald</em> (Fairfax, VA), Dec. 10, 1948.',
        year: 1948
    },
    8: {
        title: 'Leeton For Sale',
        filePath: 'press/alexandria_gazette_6_may_1846_leeton_sale.JPG',
        citation: '"For Sale or Rent", <em>Alexandria Gazette &amp; Daily Advertiser</em>, May 6, 1846.',
        year: 1846
    },
    9: {
        title: '"Rose Cottage" for Sale',
        filePath: 'press/alexandria_gazette_15_august_1890_ab_stowell_rose_cottage.jpg',
        citation: '"For Sale or Rent", <em>Alexandria Gazette</em>, August 15, 1890.',
        year: 1890
    },
    10: {
        title: 'Leslie Coates - A Proud Man',
        filePath: 'press/northern_virginia_sun_15_sept_1959_leslie_coates.JPG',
        citation: 'Farber, Howard. "Leslie R. Coates- A Proud Man." <em>Northern Virginia Sun</em>. September 15, 1959.',
        year: 1959
    },
    11: {
        title: 'Commissioner\'s Sale of Valuable Real Estate',
        filePath: 'press/alexandria_gazette_4_october_1867_mayo_janney_sale.JPG',
        citation: '"For Sale or Rent", <em>Alexandria Gazette &amp; Daily Advertiser</em>, Oct 4, 1867.',
        year: 1867
    },
    12: {
        title: 'Trustee\'s Sale of a Valuable Farm in Fairfax County, Virginia',
        filePath: 'press/alexandria_gazette_8_may_1876_jc_wall_janney_farm.JPG',
        citation: '"For Sale or Rent", <em>Alexandria Gazette &amp; Daily Advertiser</em>, May 8, 1876.',
        year: 1876
    },
    13: {
        title: 'Sacrifice Sale of Valuable Real Estate',
        filePath: 'press/fairfax_herald_thorn_may_5_1922.jpeg',
        citation: '“Sacrifice Sale of Valuable Real Estate”, <em>Fairfax Herald</em> (Fairfax, VA), May 5, 1922.',
        year: 1922
    },
    14: {
        title: 'Blue Ridge Airport Flying Club',
        filePath: 'press/10-30-1941-Loudoun_news_vol_5_LNS19411030.1.5-62-888-1277-602-426w.jpg',
        citation: '"Blue Ridge Airport", <em>Loudoun News</em>, October 30, 1941.',
        year: 1941
    },
    15: {
        title: 'Wm. Fairfax Lee Dead',
        filePath: 'press/wf_lee_death.jpg',
        citation: '"Wm. Fairfax Lee Dead", <em>Manassas Journal</em>, Volume 16, Number 43, March 17 1911.',
        year: 1911
    },
    16: {
        title: 'Trustee\'s Sale of Land',
        filePath: 'press/alexandria_gazette_11_may_1847_talmadge_thorn_property.JPG',
        citation: '"For Sale", <em>Alexandria Gazette &amp; Daily Advertiser</em>, May 11, 1847.',
        year: 1847
    },
    17: {
        title: 'House Movers Clear Chantilly Site',
        filePath: 'press/northern_virginia_sun_23_sept_1958_townsend_harrison_house_move.JPG',
        citation: 'Northern Virginia Sun. "House Movers Clear Chantilly Site." <em>Northern Virginia Sun</em>. September 23, 1958.',
        year: 1958
    },
    18: {
        title: 'Bootleggers Prosecuted',
        filePath: 'press/Loudoun_Times_mirror_1922_04_25_Bootlegging_Pleasant_valley_Closeup.jpg',
        citation: '"Fairfax News", <em>Loudoun Times-Mirror</em>, April 25, 1922.',
        year: 1922

    },
    19: {
        title: 'Smithson Tract Sale',
        filePath: 'press/alexandria_gazette_4_september_1869_halbert_sold_stowell.JPG',
        citation: '"Land Sale", <em>Alexandria Gazette</em>,  September 4, 1869.',
        year: 1869
    },
    20: {
        title: 'Sale of Land & Tavern',
        filePath: 'press/alexandria_gazette_23_november_1843_elijah_hutchison_sale_east_cub_run.JPG',
        citation: '"For Sale", <em>Alexandria Gazette</em>,  November 23, 1843.',
        year: 1843
    },
    21: {
        title: 'Closing-Out Sale at Pleasant Valley Store',
        filePath: 'press/man_journal_june_9_1922.jpg',
        citation: '"Closing-Out Sale", <em>Manassas Journal</em>, Volume 28, Number 4, June 9, 1922.',
        year: 1922
    },
    22: {
        title: 'Dental Notice',
        filePath: 'press/fairfax_herald_volume_45_dec_10_1926.jpg',
        citation: '“Dental Notice”, <em>Fairfax Herald</em> (Fairfax, VA), Volume 45, Dec. 10, 1926.',
        year: 1926
    },
    23: {
        title: 'Pleasant Valley School Property for Sale',
        filePath: 'press/loudoun_news_vol_3_num_28_1939_pleasant_valley_school_sale.jpg',
        citation: '"For Sale", <em>Loudoun News</em>, Volume 3, Number 28, June 22, 1939.',
        year: 1939
    },
    24: {
        title: 'Albion For Sale',
        filePath: 'press/Daily_National_Intelligencer_1821-07-31.png',
        citation: '"For Sale", <em>Daily National Intelligencer</em>, July 31, 1821.',
        year: 1821
    },
    25: {
        title: 'Private Education',
        filePath: 'press/brent_9_11_1832_sully.png',
        citation: '"Private Education", <em>Daily National Intelligencer</em>, September 11, 1832.',
        year: 1832
    },
    26: {
        title: 'A Terrible Accident',
        filePath: 'press/alexandria_gazette_12_september_1890_manley_hutchison.jpg',
        citation: '"News: A Terrible Accident", <em>Alexandria Gazette</em>,  September 12, 1890.',
        year: 1890
    },
    27: {
        title: 'Virginia News',
        filePath: 'press/alexandria_gazette_vol_104_num_131_2_june_1903.jpg',
        citation: '"Virginia News", <em>Alexandria Gazette</em>, June 2, 1903.',
        year: 1903
    },
    28: {
        title: 'Fairfax County School Board',
        filePath: 'press/fairfax_standard_15_nov_1946.jpg',
        citation: '"Structure of the County School System", <em>Fairfax Standard</em>, Volume 1, Number 39, November 15, 1946.',
        year: 1946
    },
    29: {
        title: 'Fairfax 4 H Winners',
        filePath: 'press/northern_virginia_sun_6_may_1958_clara_middleton.jpg',
        citation: '"Fairfax 4H Winners", <em>Northern Virginia Sun</em>. May 6, 1958.',
        year: 1958
    },
    30: {
        title: 'Ten Years Ago',
        filePath: 'press/northern_virginia_sun_blackwelder.jpg',
        citation: '"Ten Years Ago", <em>Northern Virginia sun</em>. September 17, 1973.',
        year: 1973
    },
    31: {
        title: 'C.L. Zoll Stricken with Paralysis at His Chantilly Home',
        filePath: 'press/fairfax_herald_volume_48_number_15_23_august_1929.jpg',
        citation: '“C.L. Zoll Stricken with Paralysis at His Chantilly Home”, Fairfax Herald (Fairfax, VA), Volume 48, Aug. 23, 1929.',
        year: 1929
    },
    32: {
        title: 'Mr. Zoll Dead',
        filePath: 'press/fairfax_herald_volume_48_number_23_18_october_1929.jpg',
        citation: '“Former Mayor of Fairfax Passed Away Monday”, Fairfax Herald (Fairfax, VA), Volume 48, Oct. 18, 1929.',
        year: 1929
    },
    33: {
        title: 'Mrs. Janney Dies',
        filePath: 'press/susan_janney_death.jpg',
        citation: '"Mrs. Janney Dies; Burial At Oakwood", Troy Messenger (Troy, AL), June 5, 1937.',
        year: 1937
    },
    34: {
        title: 'Alexander Turley Dies',
        filePath: 'press/alex_turley_death.jpg',
        citation: '"Died.", <em>Alexandria Gazette</em>, November 29, 1853.',
        year: 1853
    },
    35: {
        title: 'Sale of Fairfax County Lands',
        filePath: 'press/alexandria_gazette_volume_81_number_234_6_october_1880.jpg',
        citation: '"Sale of Fairfax County Lands", <em>Alexandria Gazette</em>, October 6, 1880.',
        year: 1880
    },
    36: {
        title: 'Robert Wrenn Dead',
        filePath: 'press/news_observer_03_19_robert_wrenn_dead.jpg',
        citation: '"Robert Wrenn Dead", <em>News-Observer</em>, March 19, 1925.',
        year: 1925
    },
    37: {
        title: 'Herndon Man Breaks Hand in Auto Crash',
        filePath: 'press/Blue_Ridge_Herald_19551222_Crash.jpg',
        citation: '"Herndon Man Breaks Hand in Auto Crash", <em>Blue Ridge Herald</em>, December 22, 1955.',
        year: 1955
    },
    38: {
        title: 'For Sale',
        filePath: 'press/Loudoun_News_Blue_Meadow_Sale_Nov_29_1945.jpg',
        citation: '"For Sale", <em>Loudoun News</em>, November 29, 1945.',
        year: 1945
    },
    39: {
        title: 'Proposed Road to Enter Loudoun Near Willard',
        filePath: 'press/blue_ridge_herald_02_17_1955_proposed_road.jpg',
        citation: '"Proposed Road to Enter Loudoun Near Willard", <em>Blue Ridge Herald</em>, February 17, 1955.',
        year: 1955
    },
    40: {
        title: 'Broad Run Man Charged With Murder',
        filePath: 'press/blue_ridge_herald_aug_16_1951_murder_willard.jpg',
        citation: '"Broad Run Man Charged With Murder", <em>Blue Ridge Herald</em>, August 16, 1951.',
        year: 1951
    },
    41: {
        title: 'Two Bad Fires Occurred in Dranesville District Recently.',
        filePath: 'press/fairfax_herald_home_fires_1932_02_05.jpg',
        citation: '"Two Bad Fires Occurred in Dranesville District Recently.", <em>Fairfax Herald</em>, February 5, 1932.',
        year: 1932
    },
    42: {
        title: 'Obituary of A.C.H. Darne',
        filePath: 'press/alexandria_gazette_1907_08_24_darne_death.jpg',
        citation: '"Obituary", <em>Alexandria Gazette</em>, August 24, 1907.',
        year: 1907
    },
    43: {
        title: 'Trustee\'s Sale of Dairy Cattle',
        filePath: 'press/fairfax_herald_10_13_1922_dairy_cattle_shear_willard.jpg',
        citation: '"Trustee\'s Sale of Dairy Cattle", <em>Fairfax Herald</em>, October 13, 1922.',
        year: 1922
    },
    44: {
        title: '$10 Reward',
        filePath: 'press/fairfax_herald_woodruff_reward_1937_11_05.jpg',
        citation: '"$10 Reward", <em>Fairfax Herald</em>, November 5, 1937.',
        year: 1937
    },
    45: {
        title: 'For Rent, Woodruff\'s Corner',
        filePath: 'press/fairfax_herald_1948_05_07_rent.jpg',
        citation: '"For Rent", <em>Fairfax Herald</em>, May 7, 1948.',
        year: 1948
    },
    46: {
        title: 'Chantilly Building Supply and Construction Service',
        filePath: 'press/fairfax_herald_1947_07_25_chantilly_building_supply.jpg',
        citation: '"Chantilly Building Supply and Construction Service", <em>Fairfax Herald</em>, July 25, 1947.',
        year: 1947
    },
    47: {
        title: 'Fair-Lou Arrow, for sale at Dunn\'s Garage',
        filePath: 'press/news_observer_1927_12_29_fair_lou_dunns.jpg',
        citation: '"Fair-Lou Arrow", <em>News-Observer</em>, December 29, 1927.',
        year: 1927
    },
    48: {
        title: 'School Board Meeting Minutes: Renting the disused Chantilly school house for use as a commercial laundry',
        filePath: 'press/fairfax_herald_chantilly_schoolhouse_laundry_1927_08_12.jpg',
        citation: '"School Board Meeting Minutes", <em>Fairfax Herald</em>, August 12, 1927',
        year: 1927
    },
    49: {
        title: 'Four Die in Plane Crash South of Dulles Airport',
        filePath: 'press/plane_crash_northern_virginia_sun_10_02_1965.jpg',
        citation: '"Four Die in Plane Crash South of Dulles Airport", <em>Northern Virginia Sun</em>, October 2, 1965.',
        year: 1965
    },
    50: {
        title: 'Haight Property For Sale',
        filePath: 'press/FHE18911030.1.2-5219-3248-713-578-713w.jpg',
        citation: '"For Sale", <em>Fairfax Herald</em>, October 30, 1891.',
        year: 1891
    },
    51: {
        title: 'Pleasure and Profit Society meeting in Chantilly school house',
        filePath: 'press/FHE18920304.1.3-1680-6600-765-243-765w.jpg',
        citation: '"Chantilly News", <em>Fairfax Herald</em>, March 4, 1892.',
        year: 1892
    },
    52: {
        title: 'Picnic in Captain Dey\'s Woods',
        filePath: 'press/FHE18880706.1.3-1774-5746-720-216-720w.jpg',
        citation: '"Herndon News", <em>Fairfax Herald</em>, July 6, 1888.',
        year: 1888
    },
    53: {
        title: 'Large store room being built on Ox Road',
        filePath: 'press/FHE18910123.1.3-1066-1298-704-148-704w.jpg',
        citation: '"Herndon News", <em>Fairfax Herald</em>, January 23, 1891.',
        year: 1891
    },
    54: {
        title: 'Israel Cook arrested',
        filePath: 'press/FHE18910123.1.3-339-2566-709-435-709w.jpg',
        citation: '"Herndon News", <em>Fairfax Herald</em>, January 23, 1891.',
        year: 1891
    },
    55: {
        title: 'Rural Route No. 1',
        filePath: 'press/FHE19110428.1.3-920-1523-715-855-715w.jpg',
        citation: '"Rural Route No. 1, Corrected", <em>Fairfax Herald</em>, April 28, 1911.',
        year: 1911
    },
    56: {
        title: 'Chantilly Robbers in Custody',
        filePath: 'press/FHE19300207.1.1-1636-6532-738-1300-738w.jpg',
        citation: '"Those Alleged to have Robbed Chantilly Store in Custody", <em>Fairfax Herald</em>, February 7, 1930.',
        year: 1930
    },
    57: {
        title: 'Runaway Slave Jailed',
        filePath: 'press/PG18260105.1.2-3708-6366-1132-621-1132w.jpg',
        citation: '"Was Committed", <em>Phenix Gazette</em>, January 5, 1826.',
        year: 1826
    },
    58: {
        title: 'Saddle Bag and Bonds Stolen',
        filePath: 'press/AGDA18190824.1.3-100-3442-1109-1467-1109w.jpg',
        citation: '"Twenty Dollars Reward", <em>Alexandria Gazette and Daily Advertiser</em>, August 24, 1819.',
        year: 1819
    },
    59: {
        title: 'Darne Land For Sale',
        filePath: 'press/PG18250702.1.3-2989-1094-912-2100-912w.jpg',
        citation: '"For Sale", <em>Phenix Gazette</em>, July 2, 1825.',
        year: 1825
    },
    60: {
        title: 'Runaway Slave from Hermitage',
        filePath: 'press/PG18280130.1.2-4639-3886-1108-1179-1108w.jpg',
        citation: '"Fifty Dollars Reward", <em>Phenix Gazette</em>, January 30, 1828.',
        year: 1828
    },
    61: {
        title: 'Baby Born in A Snowbound Car',
        filePath: 'press/northern_virginia_sun_17_feb_1958_baby_born_centreville_rd.JPG',
        citation: '"Born in A Snowbound Car", <em>Northern Virginia Sun</em>, February 17, 1958.',
        year: 1958
    },
    62: {
        title: 'Dynamite Recovered',
        filePath: 'press/northern_virginia_sun_30_june_1976_dynamite_frying_pan.JPG',
        citation: '"Dynamite Recovered", <em>Northern Virginia Sun</em>, June 30, 1976.',
        year: 1976
    },
    63: {
        title: 'Slaves Wanted',
        filePath: 'press/alexandria_gazette_31_may_1858_slaves_wanted_stuart.JPG',
        citation: '"Slaves Wanted", <em>Alexandria Gazette</em>, May 31, 1858.',
        year: 1858
    },
    64: {
        title: '168 Acres for Sale',
        filePath: 'press/alexandria_gazette_20_january_1870_horsepen_farm_sale.JPG',
        citation: '"Commissioners\' Sale of Land", <em>Alexandria Gazette</em>, January 20, 1870.',
        year: 1870
    },
    65: { // unused
        title: '29-Tract Payoff Made To Owners',
        filePath: 'press/HCT19580529.1.1-4098-3125-625-3311-625w.jpg',
        citation: '"29-Tract Payoff Made To Owners", <em>Herndon-Chantilly Times</em>, May 29, 1958.',
        year: 1958
    },
    66: { // unused
        title: 'Add $400,000 To Airport Land Taken',
        filePath: 'press/HCT19580605.1.1-1822-752-597-3038-597w.jpg',
        citation: '"Add $400,000 To Airport Land Taken", <em>Herndon-Chantilly Times</em>, June 5, 1958.',
        year: 1958
    },
    67: { // unused
        title: 'Sitting It Out',
        filePath: 'press/HCT19580626.1.1-2517-1031-1673-2105-1673w.jpg',
        citation: '"Sitting It Out", <em>Herndon-Chantilly Times</em>, June 26, 1958.',
        year: 1958
    },
    68: { // unused
        title: 'Landowners Protest Appraisals',
        filePath: 'press/landowners_protest_appraisals_06_26_1958.jpg',
        citation: '"Landowners Protest Appraisals", <em>Herndon-Chantilly Times</em>, June 26, 1958.',
        year: 1958
    },
    69: { // unused
        title: 'Land Value Was Problem Back In Frying Pan Days',
        filePath: 'press/land_value_frying_pan_06_26_1958.jpg',
        citation: '"Land Value Was Problem Back In Frying Pan Day", <em>Herndon-Chantilly Times</em>, June 26, 1958.',
        year: 1958
    },
    70: { // unused, little rock 9 racist commentary
        title: 'Triumph of Reason Over Force',
        filePath: 'press/HCT19580626.1.4-115-248-1862-2277-1862w.jpg',
        citation: '"Triumph of Reason Over Force", <em>Herndon-Chantilly Times</em>, June 26, 1958.',
        year: 1958
    },
    71: { // unused, first to move. Willard.
        title: 'First Owner Moves Off Airport Site',
        filePath: 'press/HCT19580703.1.1-2471-1104-1681-1840-1681w.jpg',
        citation: '"First Owner Moves Off Airport Site", <em>Herndon-Chantilly Times</em>, July 3, 1958.',
        year: 1958
    },
    72: {
        title: 'First Checks Given Airport Landowners',
        filePath: 'press/HCT19580703.1.1-4142-1143-656-2262-656w.jpg',
        citation: '"First Checks Given Airport Landowners", <em>Herndon-Chantilly Times</em>, July 3, 1958.',
        year: 1958
    },
    73: {
        title: 'Chantilly Fire Carnival Open',
        filePath: 'press/HCT19580710.1.1-2421-4230-653-1304-653w.jpg',
        citation: '"Chantilly Fire Carnival Open", <em>Herndon-Chantilly Times</em>, July 10, 1958.',
        year: 1958
    },
    74: {
        title: 'New Hearing Ordered On Airport Date',
        filePath: 'press/HCT19580717.1.1-127-694-701-4289-701w.jpg',
        citation: '"New Hearing Ordered On Airport Date", <em>Herndon-Chantilly Times</em>, July 17, 1958.',
        year: 1958
    },
    75: {
        title: 'Legal Point Blocks Move of Church, Five Graves from Jet Airport Site',
        filePath: 'press/obstacle_shiloh_church_graves_july_24_1958_HCT.jpg',
        citation: '"Legal Point Blocks Move of Church, Five Graves from Jet Airport Site", <em>Herndon-Chantilly Times</em>, July 24, 1958.',
        year: 1958
    },
    76: {
        title: 'Airport Site Clearance Scheduled in 150 Days',
        filePath: 'press/HCT19580724.1.1-824-637-2256-2943-564w.jpg',
        citation: '"Airport Site Clearance Scheduled in 150 Day", <em>Herndon-Chantilly Times</em>, July 24, 1958.',
        year: 1958
    },
    77: {
        title: 'Landowner Challenges Government',
        filePath: 'press/HCT19580807.1.1-2484-4881-2345-1443-1173w.jpg',
        citation: '"Landowner Challenges Government", <em>Herndon-Chantilly Times</em>, August 7, 1958.',
        year: 1958
    },
    78: {
        title: 'Social Held By Filipino Students',
        filePath: 'press/HCT19580807.1.2-1185-5029-607-1351-607w.jpg',
        citation: '"Social Held By Filipino Students", <em>Herndon-Chantilly Times</em>, August 7, 1958.',
        year: 1958
    },
    79: {
        title: 'Add Fourth Runway for Heavy Traffic',
        filePath: 'press/HCT19580814.1.1-2478-1328-636-3127-636w.jpg',
        citation: '"Add Fourth Runway for Heavy Traffic", <em>Herndon-Chantilly Times</em>, August 14, 1958.',
        year: 1958
    },
    80: {
        title: 'Big Cat Mows Hedgerow to Start Airport',
        filePath: 'press/HCT19580904.1.1-196-317-4591-1884-4591w.jpg',
        citation: '"Big Cat Mows Hedgerow to Start Airport", <em>Herndon-Chantilly Times</em>, September 4, 1958.',
        year: 1958
    },
    81: {
        title: 'For Sale - Houses - Barns and Misc Structures',
        filePath: 'press/HCT19580918.1.6-75-2811-2388-3569-2388w.jpg',
        citation: '"For Sale - Houses - Barns and Misc Structures", <em>Herndon-Chantilly Times</em>, September 18, 1958.',
        year: 1958
    },
    82: { // Racism
        title: 'Crosses Burned At Oak Grove',
        filePath: 'press/HCT19581023.1.1-4093-5150-671-1295-671w.jpg',
        citation: '"Crosses Burned At Oak Grove", <em>Herndon-Chantilly Times</em>, October 23, 1958.',
        year: 1958
    },
    83: {
        title: 'Work Goes On At Chantilly Airport',
        filePath: 'press/HCT19581127.1.6-1865-193-1738-1314-1738w.jpg',
        citation: '"Work Goes On At Chantilly Airport", <em>Herndon-Chantilly Times</em>, November 27, 1958.',
        year: 1958
    },
    84: {
        title: 'Biggest in World for Airport Site',
        filePath: 'press/HCT19581218.1.1-1837-4621-2337-1842-2337w.jpg',
        citation: '"Biggest in World for Airport Site", <em>Herndon-Chantilly Times</em>, December 18, 1958.',
        year: 1958
    },
    85: {
        title: 'FAA Orders Go-Ahead For Airport Runways',
        filePath: 'press/HCT19590108.1.1-1836-1664-1267-1689-1267w.jpg',
        citation: '"FAA Orders Go-Ahead For Airport Runways", <em>Herndon-Chantilly Times</em>, January 8, 1959.',
        year: 1959
    },
    86: {
        title: 'Airport Road Will Not Harm Sully',
        filePath: 'press/HCT19590226.1.3-2423-553-746-1679-746w.jpg',
        citation: '"Airport Road Will Not Harm Sully", <em>Herndon-Chantilly Times</em>, February 26, 1959.',
        year: 1959
    },
    87: {
        title: 'Chantilly',
        filePath: 'press/HCT19590514.1.4-2592-219-965-3064-965w.jpg',
        citation: '"Chantilly", <em>Herndon-Chantilly Times</em>, May 14, 1959.',
        year: 1959
    },
    88: {
        title: 'Chantilly VFD\'s New Truck',
        filePath: 'press/HCT19590625.1.1-176-4752-1707-1536-1707w.jpg',
        citation: '"Chantilly VFD\'s New Truck", <em>Herndon-Chantilly Times</em>, June 25, 1959.',
        year: 1959
    },
    89: {
        title: 'Here is How 5,000 Yards of Concrete Are Poured Daily For Runway',
        filePath: 'press/HCT19590702.1.3-188-202-4628-1780-4628w.jpg',
        citation: '"Here is How 5,000 Yards of Concrete Are Poured Daily For Runway", <em>Herndon-Chantilly Times</em>, July 2, 1959.',
        year: 1959
    },
    90: {
        title: 'Concrete Pouring Moves Ahead at Chantilly Airport',
        filePath: 'press/HCT19590723.1.3-722-309-3420-1794-3420w.jpg',
        citation: '"Concrete Pouring Moves Ahead at Chantilly Airport", <em>Herndon-Chantilly Times</em>, July 23, 1959.',
        year: 1959
    },
    91: {
        title: 'Runway Base Material Being Spread',
        filePath: 'press/HCT19590820.1.3-1904-250-1728-1712-1728w.jpg',
        citation: '"Runway Base Material Being Spread", <em>Herndon-Chantilly Times</em>, August 20, 1959.',
        year: 1959
    },
    92: {
        title: 'N.Y. Firm Controls $2 Million Land Chunk North of Airport',
        filePath: 'press/HCT19591022.1.1-2957-4950-1843-1426-1843w.jpg',
        citation: '"N.Y. Firm Controls $2 Million Land Chunk North of Airport", <em>Herndon-Chantilly Times</em>, October 22, 1959.',
        year: 1959
    },
    93: {
        title: '18 Russian Farmers Spend Three Hours at Crossman Farm',
        filePath: 'press/russian_farmers_26_nov_1959_HCT.jpg',
        citation: '"18 Russian Farmers Spend Three Hours at Crossman Farm", <em>Herndon-Chantilly Times</em>, November 26, 1959.',
        year: 1959
    },
    94: {
        title: 'Moving Is Not What It Used To Be',
        filePath: 'press/HCT19591217.1.1-161-4286-1719-2078-1719w.jpg',
        citation: '"Moving Is Not What It Used To Be", <em>Herndon-Chantilly Times</em>, December 17, 1959.',
        year: 1959
    },
    95: {
        title: 'Hanna House Demolished',
        filePath: 'press/HCT19600602.1.1-2399-4561-1725-1909-1725w.jpg',
        citation: '"Hanna House Demolished", <em>Herndon-Chantilly Times</em>, June 2, 1960.',
        year: 1960
    },
    96: {
        title: 'New Type Radar Will Increase Safety In Chantilly Airport Area',
        filePath: 'press/HCT19600721.1.6-1154-325-1889-2396-1889w.jpg',
        citation: '"New Type Radar Will Increase Safety In Chantilly Airport Area", <em>Herndon-Chantilly Times</em>, July 21, 1960.',
        year: 1960
    },
    97: {
        title: 'Two Chantilly Workmen Injured in Equipment Crash',
        filePath: 'press/HCT19600728.1.4-37-3224-1273-1008-1273w.jpg',
        citation: '"Two Chantilly Workmen Injured in Equipment Crash", <em>Herndon-Chantilly Times</em>, July 28, 1960.',
        year: 1960
    },
    98: {
        title: 'A Pictorial Progress Report on Chantilly International Airport Construction',
        filePath: 'press/HCT19600901.1.6-147-382-4616-4377-4616w.jpg',
        citation: '"A Pictorial Progress Report on Chantilly International Airport Construction", <em>Herndon-Chantilly Times</em>, September 1, 1960.',
        year: 1960
    },
    99: {
        title: 'An Aerial Photo Shows the Progress of Work',
        filePath: 'press/HCT19601117.1.1-1905-1219-2277-1929-2277w.jpg',
        citation: '"An Aerial Photo Shows the Progress of Work", <em>Herndon-Chantilly Times</em>, November 17, 1960.',
        year: 1960
    },
    100: {
        title: 'Giant Shop-Pay Center Planned On 91 Chantilly Acres',
        filePath: 'press/HCT19601208.1.1-106-732-1207-4437-1207w.jpg',
        citation: '"Giant Shop-Pay Center Planned On 91 Chantilly Acres", <em>Herndon-Chantilly Times</em>, December 8, 1960.',
        year: 1960
    },
    101: {
        title: 'Avon Farm Estates Auction',
        filePath: 'press/NWSOB19280510.1.8-266-336-4147-6060-4147w.jpg',
        citation: '"Avon Farm Estates Auction", <em>News-Observer</em>, May 10, 1928.',
        year: 1928
    }
};
