import React from 'react';
import {
    summaryContent
} from './summaries';

export function centerMap(mapRef, coordinates, zoom) {
    mapRef.current.target.setView(coordinates, zoom);
}
export function footnote(number) {
    return (<sup>{number}</sup>);
}

export function nap(ms) {
    return Promise((resolve) => setTimeout(resolve, ms));
}

// Map-specific utilities

export function generateLandmarkLabel(feature, currentYear) {
    let landmarkLabel = '';

    if (currentYear === 1957) {
        if (feature.properties.estate_name_1957 !== null) {
            landmarkLabel = feature.properties.estate_name_1957;
        } else if (feature.properties.estate_name !== null) {
            landmarkLabel = feature.properties.estate_name;
        } else {
            landmarkLabel = feature.properties.name;
        }
    } else if (currentYear === 1870) {
        if (feature.properties.estate_name !== null) {
            landmarkLabel = feature.properties.estate_name;
        } else if (feature.properties.owner_1870ish !== null) {
            landmarkLabel = feature.properties.owner_1870ish;
        } else if (feature.properties.owner_1860 !== null) {
            landmarkLabel = feature.properties.owner_1860;
        } else {
            landmarkLabel = feature.properties.name;
        }
    } else if (currentYear === 1830) {
        if (feature.properties.estate_name !== null) {
            landmarkLabel = feature.properties.estate_name;
        } else if (feature.properties.owner_1820 !== null) {
            landmarkLabel = feature.properties.owner_1820;
        } else {
            landmarkLabel = feature.properties.name;
        }
    } else if (feature.properties.estate_name_1957 !== null) {
        landmarkLabel = feature.properties.estate_name_1957;
    } else if (feature.properties.name !== null) {
        landmarkLabel = feature.properties.name;
    }
    return landmarkLabel;
}

export function generatePlaceLabel(feature, currentYear) {
    let field = 'name_1960';
    if (currentYear === 1830) {
        field = 'name_1800';
    }
    if (currentYear === 1870) {
        field = 'name_1860';
    }
    if (currentYear === 1994 || currentYear === 'today') {
        field = 'name_today';
    }
    return feature.properties[field];
}
export function generateLandGrantColor(acreage, max) {
    return `hsl(${360 * ((acreage / max))},${25 + 70 * (acreage / max)}%,${
        60 + 10 * (acreage / max)
    }%)`;
}

export function getFileNameWithoutExtension(filePath) {
    // Use the lastIndexOf method to find the last occurrence of "/"
    // or "\" in the filePath to get the filename without the path
    const pathSeparatorIndex = Math.max(filePath.lastIndexOf('/'), filePath.lastIndexOf('\\'));
    let fileNameWithoutPath = filePath.substring(pathSeparatorIndex + 1);

    // Use the indexOf method to find the first occurrence of "." in the filename
    const dotIndex = fileNameWithoutPath.lastIndexOf('.');

    // If a dot is found and it's not the first character (e.g., for hidden files),
    // remove the file extension
    if (dotIndex !== -1 && dotIndex !== 0) {
        fileNameWithoutPath = fileNameWithoutPath.substring(0, dotIndex);
    }

    return fileNameWithoutPath;
}

export function coordinateCopyCallback(e) {
    // Get the coordinates from the right-click event
    const { lat, lng } = e.latlng;
    // Create a temporary input element to copy the coordinates to the clipboard
    const input = document.createElement('input');
    input.value = `${lat}, ${lng}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    // You can also display a notification to indicate that the coordinates have been copied.
    console.log(`Coordinates copied: ${lat}, ${lng}`);
}
export function generateStreetViewLink(photo) {
    return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=
    ${photo.coordinates[0]}%2c${photo.coordinates[1]}&heading=${photo.heading}`;
}

export function generateMapsLink(photo) {
    return `https://www.google.com/maps/search/?api=1&query=${photo.coordinates[0]},${photo.coordinates[1]}`;
}

export function openToGoogleCallback(e) {
    // Get the coordinates from the right-click event
    const { lat, lng } = e.latlng;
    window.open(generateMapsLink({ coordinates: [lat, lng] }), '_blank');
}

export function shareLandmarkLink(uid) {
    const currentHost = window.location.host;
    const input = document.createElement('input');
    input.value = `${currentHost}/landmark?uid=${uid}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}

export function shareSummaryLink(id) {
    const currentHost = window.location.host;
    const input = document.createElement('input');
    input.value = `${currentHost}/summary?id=${id}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}

export function sharePhotoLink(id) {
    const currentHost = window.location.host;
    const input = document.createElement('input');
    input.value = `${currentHost}/image?id=${id}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}

export function getFunctionalYear(currentYear) {
    let functionalYear = 1957;
    if (currentYear === 1957) {
        functionalYear = 1960;
    } else if (currentYear === 1870) {
        functionalYear = 1860;
    } else if (currentYear === 1830) {
        functionalYear = 1800;
    }
    return functionalYear;
}

export function isSummaryPopulated(communityId, place, currentYear, placeName) {
    const functionalYear = getFunctionalYear(currentYear);
    let foundId = null;
    if (place) {
        foundId = Object.keys(summaryContent).find((key) => summaryContent[key].name === place.properties[`name_${functionalYear}`] || summaryContent[key].altName === place.properties[`name_${functionalYear}`]);
    } else if (communityId) {
        foundId = communityId;
    } else if (placeName) {
        foundId = Object.keys(summaryContent).find((key) => summaryContent[key].name === placeName);
    }
    if (foundId && summaryContent[foundId].summary.length > 0) {
        return foundId;
    }
    return false;
}
