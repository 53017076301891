/* eslint-disable no-loss-of-precision */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPlats: [],
    plats: []
};

export const platSlice = createSlice({
    name: 'plat',
    initialState,
    reducers: {
        setCurrentPlats: (state, action) => {
            state.currentPlats = action.payload;
        },
        removePlat: (state, action) => {
            // Removes the specified string from the currentPlats array
            state.currentPlats = state.currentPlats.filter((plat) => plat !== action.payload.layer);
        }
    }
});

export const {
    removePlat,
    setCurrentPlats
} = platSlice.actions;

export const selectCurrentPlats = (state) => state.plat.currentPlats;
export default platSlice.reducer;
