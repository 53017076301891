import React, { useEffect } from 'react';
import {
    Snackbar
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { selectLatest, setLatest } from './dialogSlice';
import {
    selectLandmarks
} from '../landmark/landmarkSlice';

import './dialog.css';

export default function Latest() {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const latest = useSelector(selectLatest);
    const landmarks = useSelector(selectLandmarks);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (landmarks && landmarks.length) {
            const lastFiveLandmarksNames = landmarks.slice(-5).map((landmark) => landmark.properties.name);
            const landmarkString = `Recently added landmarks: ${lastFiveLandmarksNames.join(', ')}`;
            dispatch(setLatest(landmarkString));
        }
    }, []);

    // Conditional rendering based on the 'latest' variable
    if (!latest) {
        // You can render null or some loading component if 'latest' is not yet available
        return null; // or <LoadingComponent />
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'left', vertical: 'middle' }}
            message={latest}
        />
    );
}
