import React from 'react';
import AppBar from '@mui/material/AppBar';
import YearPicker from './features/year/YearPicker';
import Map from './features/map/Map';
import Splash from './features/dialogs/Splash';
import Latest from './features/dialogs/Latest';
import About from './features/dialogs/About';
import Tutorial from './features/dialogs/Tutorial';
import './App.css';

import TopBar from './features/topBar/topBar';
import CloserLook from './features/closerLook/closerLook';
import Summary from './features/summary/Summary';

class App extends React.Component {
    render() {
        return (
            <div className="App">
                <AppBar component="nav">
                    <TopBar />
                </AppBar>
                <Map />
                <YearPicker />
                <CloserLook />
                <About />
                <Splash />
                <Latest />
                <Tutorial />
                <Summary />

            </div>
        );
    }
}

export default App;
