import React from 'react';
import { useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import {
    Button, Menu, MenuItem, Toolbar, Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlatPicker from '../plats/platPicker';
import SpecialLayers from './specialLayers';
import './topBar.css';
import { setAbout, setTutorial } from '../dialogs/dialogSlice';
import { setCurrentCommunity } from '../map/mapSlice';
import { setCurrentSummary } from '../summary/summarySlice';
import {
    articles, communities
} from '../../app/constants';
import { isSummaryPopulated } from '../../app/utilities';

export default function TopBar() {
    const [communityAnchorEl, setCommunityAnchorEl] = React.useState(null);
    const [articleAnchorEl, setArticleAnchorEl] = React.useState(null);
    const communityOpen = Boolean(communityAnchorEl);
    const articleOpen = Boolean(articleAnchorEl);
    const dispatch = useDispatch();
    const handleCommunityClick = (event) => {
        setCommunityAnchorEl(event.currentTarget);
    };
    const handleCommunityClose = () => {
        setCommunityAnchorEl(null);
    };
    const handleArticleClick = (event) => {
        setArticleAnchorEl(event.currentTarget);
    };
    const handleArticleClose = () => {
        setArticleAnchorEl(null);
    };
    const openIfSummary = (communityId) => {
        if (isSummaryPopulated(communityId)) {
            dispatch(setCurrentSummary(communityId));
        }
        const urlParams = new URLSearchParams({
            id: communityId
        });
        window.history.replaceState(null, '', `summary?${urlParams.toString()}`);
    };
    const openArticle = (articleId) => {
        dispatch(setCurrentSummary(articleId));
    };
    // dispatch(setCurrentSummary(community.id));
    return (
        <Toolbar variant="dense" className="top-bar">
            { (!isMobile || isIPad13) ? (
                <div style={{
                    flex: 1,
                    textAlign: 'left',
                    width: 300,
                    height: 45,
                    backgroundImage: 'url(\'bar_logo.png\')',
                    backgroundRepeat: 'no-repeat'
                }}
                />
            )
                : (
                    <div style={{
                        flex: 1,
                        textAlign: 'left',
                        width: 110,
                        height: 40,
                        marginLeft: 5,
                        backgroundSize: '55%',
                        backgroundImage: 'url(\'bar_logo_mobile.png\')',
                        backgroundRepeat: 'no-repeat'
                    }}
                    />
                )}
            { (!isMobile || isIPad13) ? (
                <Button
                    id="basic-button"
                    aria-controls={articleOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={articleOpen ? 'true' : undefined}
                    onClick={handleArticleClick}
                    variant="primary"
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Articles
                </Button>
            ) : null }
            <Menu
                id="basic-menu"
                anchorEl={articleAnchorEl}
                open={articleOpen}
                onClose={handleArticleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {articles.map((article) => (
                    <MenuItem key={article.id}>
                        <Button className="community-menuitem" onClick={() => { setArticleAnchorEl(null); openArticle(article.id); }}>{article.name}</Button>
                    </MenuItem>
                ))}
            </Menu>
            <Button
                id="basic-button"
                aria-controls={communityOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={communityOpen ? 'true' : undefined}
                onClick={handleCommunityClick}
                variant="primary"
                endIcon={<KeyboardArrowDownIcon />}
            >
                Communities
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={communityAnchorEl}
                open={communityOpen}
                onClose={handleCommunityClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                {communities.map((community) => (
                    <MenuItem key={community.id}>
                        <Button className="community-menuitem" onClick={() => { setCommunityAnchorEl(null); dispatch(setCurrentCommunity(community.id)); openIfSummary(community.id); }}>{community.name}</Button>
                    </MenuItem>
                ))}
            </Menu>
            { (!isMobile || isIPad13) ? <PlatPicker /> : null }
            {(!isMobile || isIPad13) ? <SpecialLayers /> : null}
            {(!isMobile || isIPad13)
                ? (
                    <Button
                        onClick={() => { dispatch(setTutorial(true)); }}
                        variant="primary"
                    >
                        Tutorials
                    </Button>
                ) : null }
            <Button
                onClick={() => { dispatch(setAbout(true)); }}
                variant="primary"
            >
                About
            </Button>
        </Toolbar>
    );
}
